// THIS IS THE MAIN SCSS FILE
/*========================================================
        COLORS
=========================================================*/

$primary: #7367f0;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$dark: #1e1e1e;
$grey: #b8c2cc;
$grey-light: #dae1e7;
$white: #fff;
$black: #22292f;

$elsi-color-backdrop: #f8f8f8;
$elsi-color-background: #f8f8f8;
$elsi-color-bar-bg: #ecf0f3;
$elsi-color-blue: #278cb9;
$elsi-color-lightblue: #4bb3d4;
$elsi-color-lighterblue: #a1dbed;
$elsi-color-aufgabe: #f15f62;
$elsi-color-dunkelgrau: #dae1e7;
$elsi-color-mittelgrau: #f3f6f8;

$elsi-color-schrift-schwarz: #333333;
$elsi-color-schrift-normal: #485159;
$elsi-color-schrift-graublau: #7b8b99;
$elsi-color-schrift-hellgrau: #9ca2a6;

$elsi-color-icon-grau: #818181;

$glossar-color-alle: #ecf0f3;
$glossar-color-werte: #747474;
$glossar-color-elsi: $elsi-color-lightblue;
$glossar-color-daten: $elsi-color-aufgabe;
$glossar-color-gesetz: #f2994a;

$content-color: #626262;

$star-hell: #f7ddb5;
$star-dunkel: #f2af4a;

/*========================================================
        SPACING
=========================================================*/

$spacer: 2.2rem;
$spacer-sm: calc($spacer / 2);
$spacer-lg: calc($spacer * 2);

/*========================================================
        TYPOGRAPHY
=========================================================*/

$font-family-sans-serif: "Montserrat", Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;

//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.74;
$h3-font-size: $font-size-base * 1.51;
$h4-font-size: $font-size-base * 1.32;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;

$line-height-base: 1.625rem !default;

$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: #2c2c2c;
$headings-margin-bottom: calc($spacer / 2) !default;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$content-color: #626262;
$theme-background: #f8f8f8;

/*========================================================
        LAYOUT
=========================================================*/
//HEADLINES
body {
  color: $content-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h1-font-size;
}
h2,
.h2 {
  font-size: $h2-font-size;
}
h3,
.h3 {
  font-size: $h3-font-size;
}
h4,
.h4 {
  font-size: $h4-font-size;
}
h5,
.h5 {
  font-size: $h5-font-size;
  padding-top: 20px;
}
h6,
.h6 {
  font-size: $h6-font-size;
}

//===================================================

html {
  font-size: $font-size-base;
  height: 100%;
  width: 100%;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

body {
  font-family: $font-family-sans-serif;
  background: $theme-background;
  font-weight: 400;
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
  transition: background-color 0.3s ease;
}

#app {
  min-height: 100%;
}

/*========================================================
      COMPONENTS
=========================================================*/

.padding-subpages {
  padding-right: 40px;
  padding-left: 0px;
}

.editPen {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  margin: 10px 10px 0 0;
  border-radius: 6px;
  background: #ecf0f3;
  z-index: 1;
}

label {
  display: inline !important; // 1
}

// MODAL
@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}

//NAVIGATION SIDEBAR
.layout {
  display: flex;
  flex-direction: row;
}

//LINK
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a {
  color: $elsi-color-blue;
  &:hover {
    color: $elsi-color-lightblue;
  }
  &:active {
    color: $elsi-color-lightblue;
  }
}

.custom-control-input:disabled {
  accent-color: red;
}

//BUTTON
section#sort {
  display: flex;
  flex-direction: column;
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-columns: minmax(100px, 100px) auto;
  height: 200px;

  .sort {
    height: 200px;
  }
}

.noborder {
  border: none !important;
  border-radius: 3px !important;
}

section#content-header-survey {
  display: grid;
  grid-column-gap: 1.5rem;
  padding: 16px 0 32px 0;

  .content-header-bar-survey {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: minmax(100px, 100px) auto;
    height: 5rem;
    align-content: center;
    font-weight: bold;
    grid-column-gap: 1.5rem;
  }

  .content-survey {
    grid-template-columns: minmax(100px, 100px) auto;
    grid-column-gap: 1.5rem;
    display: grid;
  }
}

[onClick] {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
.bi-chevron-down {
  cursor: pointer;
}

.nav-tabs .nav-link {
  border: none;
}
.nav-tabs {
  border: none;
}

.star-hell {
  color: white;
  background-color: $star-hell;
}

.star-dunkel {
  color: white;
  background-color: $star-dunkel;
}

.starredIcon {
  position: absolute;
  top: 16px;
  right: 16px;

  .iconStarred {
    padding: 10px;
    border-radius: 6px;
  }
}

.option-card {
  background-color: #f8f9fb;
  border: none;
}

// Close symbol in header of modal dialogs
button.close {
  padding-left: 7px;
  padding-right: 7px;
  font-size: 32px;
  border: none;
  background: none;
  color: $elsi-color-icon-grau;
}

.card-text .row ul > li {
  padding-top: 16px;
  padding-left: 16px;
}

// CHECKBOXES
div.clickable > input,
div.clickable > label {
  cursor: pointer;
}

// Skins OK/Cancel buttons in footer of b-modal dialogs according to ELSI-SAT style guide
.modal-footer-buttons {
  gap: 4px;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 12px;

    height: 47px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    text-decoration: none;
    border: 0 none;
  }

  .btn-link,
  .btn-primary {
    background: $elsi-color-blue;
    color: white;

    &:hover {
      background: darken($elsi-color-blue, 8);
    }
  }

  .btn-secondary {
    background: $elsi-color-dunkelgrau;
    color: $elsi-color-schrift-normal;

    &:hover {
      background: darken($elsi-color-dunkelgrau, 8);
    }
  }
}
