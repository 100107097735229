@import "@/assets/scss/main.scss";

section#overview-categorys {
    display: flex;
    width: 100%;
    flex-direction: column;

    .overview-categorys {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 35fr 35fr 10fr 8fr;
        grid-column-gap: 1.5rem;
        height: 5rem;
        align-content: center;
    }
}
